import { m } from 'framer-motion';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { useRouter } from 'next/navigation';
import { useSnackbar } from 'notistack';
import qs from 'qs';
import CustomPopover from './custom-popover';
import usePopover from './use-popover';
import { paths } from '@/app/utilities/consts';
import { varHover } from '../animate';
import { useUserContext } from '../contexts/user-context';
import { axiosInstance } from '@/app/utilities/helpers/axios';

const OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Profile',
    linkTo: paths.userSettings,
  },
  {
    label: 'Settings',
    linkTo: paths.appSettings,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();

  const { user } = useUserContext();

  const { enqueueSnackbar } = useSnackbar();

  const popover = usePopover();

  const handleLogout = async () => {
    try {
      const body = {};

      const response = await axiosInstance.post('logout', qs.stringify(body), {
        withCredentials: true,
      });

      const responseData = response.data;

      if (responseData.success) {
        enqueueSnackbar('Log out successful', { variant: 'success' });
      } else {
        enqueueSnackbar('Login again, something went wrong', { variant: 'error' });
      }

      router.push('/login');
    } catch (error) {
      router.push('/login');
    }
  };

  const handleClickItem = path => {
    popover.onClose();
    router.push(path);
  };

  const uniqueSuffix = new Date().getTime();

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: theme => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: theme =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        {/* // ${user?.photo_url}?v=${uniqueSuffix} */}
        <Avatar
          src={user?.photo_url ? `${user?.photo_url}?v=${uniqueSuffix}` : undefined}
          alt={user?.name}
          sx={{
            width: 36,
            height: 36,
            border: theme => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {user?.name?.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map(option => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          Logout
        </MenuItem>
      </CustomPopover>
    </>
  );
}
