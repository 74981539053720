import Box from '@mui/material/Box';

import { useResponsive } from '../../hooks/use-responsive';
import { useSettingsContext } from '../../settings';
import { NAV, HEADER } from '@/app/utilities/consts';

const SPACING = 8;

export default function Main({ children, sx, ...other }) {
  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const isNavMini = settings.themeLayout === 'mini';

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        py: `${HEADER.H_MOBILE + SPACING}px`,
        px: 3,
        ...(lgUp && {
          px: 6,
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          maxWidth: 'xl',
          mx: 'auto',
          width: `calc(100% - ${NAV.W_VERTICAL}px)`,
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI}px)`,
          }),
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
}
