import { Stack, Typography } from '@mui/material';
import { useUserContext } from '../contexts/user-context';
import Label from '../label';

export const TimezoneInfo = () => {
  const { timezone } = useUserContext();

  return (
    <Stack flexDirection="row">
      <Label variant="soft" color="success">
        {timezone}
      </Label>
    </Stack>
  );
};
